import React, { useState, useContext, useEffect, useRef } from "react";
import { auth, db, storage } from "./config";
import { Context } from "./context";
import { saveDOC } from "./word.ts";
import { ModalCreateProject } from "./modalproject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
import { faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { faDiceD6 } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";
import Slider from "./slider";

export const Evaluator = ({ user }) => {
  const { student } = useContext(Context);
  const { students } = useContext(Context);
  const { currentGroup } = useContext(Context);
  const { currentProject } = useContext(Context);
  const { scores } = useContext(Context);
  const { showStats, setShowStats } = useContext(Context);
  const { special, setSpecial } = useContext(Context);

  let copyText = null;

  const [score, setScore] = useState(0);
  const [idea, setIdea] = useState(0);
  const [norms, setNorms] = useState(0);
  const [render, setRender] = useState(0);
  const [sorted, setSorted] = useState([]);
  const [final, setFinal] = useState(0);
  const [lstToCopy, setListToCopy] = useState("");
  const [showScores, setShowScores] = useState(false);
  const [specialList, setSpecialList] = useState([]);

  const [modalCreateProject, setModalCreateProject] = useState(false);

  const [tabs, setTab] = useState([
    { role: "имени", bold: true, key: 0 },
    { role: "рейтингу", bold: false, key: 1 },
  ]);

  const [sort, setSort] = useState(tabs[0].role);

  const setActiveTab = (key) => {
    setTab((tabs) => {
      return tabs.map((tab) => {
        if (tab.key === key) {
          tab.bold = true;
        } else {
          tab.bold = false;
        }
        return tab;
      });
    });
  };

  const medianCount = (a, b, c) => {
    if (currentProject.sliders)
      return (parseFloat(a) + parseFloat(b) + parseFloat(c)) / 3;
    else return parseFloat(a);
  };

  const isMounted = useRef(false);
  const isUnscored = useRef(false);

  const unScore = (e) => {
    e.preventDefault();

    scores.delete(user.displayName.split(" ")[0]);

    // console.log("From Unscore: " + user.displayName.split(" ")[0]);

    // db.ref(currentGroup.dbName +'/group/' + student.id
    // + '/projects/' + currentProject + '/scores/' + user.displayName.split(' ')[0]).remove()

    db.ref(
      currentGroup.dbName +
        "/group/" +
        student.id +
        "/projects/" +
        currentProject.name +
        "/scores/"
    )
      .child(user.displayName.split(" ")[0])
      .remove();

    db.ref(
      currentGroup.dbName +
        "/group/" +
        student.id +
        "/projects/" +
        currentProject.name
    ).update({ special: false });

    setIdea(0);
    setNorms(0);
    setRender(0);

    isUnscored.current = false;
  };

  useEffect(() => {
    isMounted.current = false;
    isUnscored.current = false;
  }, [currentGroup]);

  useEffect(() => {
    setFinal(0.0);
    setScore(0);

    if (scores.size) {
      // console.log("From Firebase: ", scores);

      if (scores.has(user.displayName.split(" ")[0])) {
        setIdea(scores.get(user.displayName.split(" ")[0]).idea);
        setNorms(scores.get(user.displayName.split(" ")[0]).norms);
        setRender(scores.get(user.displayName.split(" ")[0]).render);

        setScore(() => {
          let value = scores.get(user.displayName.split(" ")[0]);
          return medianCount(value.idea, value.norms, value.render);
        });
        setFinal(() => {
          let final = 0.0;
          scores.forEach((value) => {
            final = final + medianCount(value.idea, value.norms, value.render);
          });
          return final / scores.size;
        });
      } else {
        isMounted.current = false;

        setIdea(0);
        setNorms(0);
        setRender(0);

        setFinal(() => {
          let final = 0.0;
          scores.forEach((value) => {
            final = final + medianCount(value.idea, value.norms, value.render);
          });
          return final / scores.size;
        });
      }
    } else {
      isMounted.current = false;

      setIdea(0);
      setNorms(0);
      setRender(0);
    }
  }, [scores]);

  useEffect(() => {
    let gr = currentGroup.dbName;
    let id = student.id;
    let proj = currentProject.name;

    if (isUnscored.current) {
      setScore((parseFloat(idea) + parseFloat(norms) + parseFloat(render)) / 3);
      let detailed = { idea: idea, norms: norms, render: render };
      scores.set(user.displayName.split(" ")[0], detailed);
      let scoresToPush = Object.fromEntries(scores.entries());
      // console.log('To send: ', scoresToPush)
      if (
        currentGroup.dbName &&
        student.id &&
        currentProject &&
        student.image
      ) {
        db.ref(gr + "/group/" + id + "/projects/" + proj).update({
          scores: scoresToPush,
          special: special,
        });
      }
      setFinal(() => {
        let final = 0.0;
        scores.forEach((value) => {
          final = final + medianCount(value.idea, value.norms, value.render);
        });
        return final / scores.size;
      });

      students.map((s) => (s.id === student.id ? (s.score = final) : null));
    } else {
      // console.log("Do nothing...");

      isUnscored.current = true;
    }
  }, [idea, norms, render]);

  const fillMedianScores = (a) => {
    students.map((student) => {
      let projects = student.projects
        ? new Map(Object.entries(student.projects))
        : new Map();
      let projectscores = projects.has(currentProject.name)
        ? Object.values(projects.get(currentProject.name))[2]
        : {};
      let scores = projectscores
        ? new Map(Object.entries(projectscores))
        : new Map();
      let final = 0.0;
      scores.forEach((value) => {
        final = final + medianCount(value.idea, value.norms, value.render);
        let median = final / scores.size;
        student.score = median.toFixed(a);
      });
    });
  };

  const save = (e) => {
    e.preventDefault();
    fillMedianScores(1);
    saveDOC(
      students,
      currentGroup.name,
      currentProject,
      currentGroup.hours
        ? currentGroup.hours[currentGroup.projects.indexOf(currentProject)]
        : ""
    );
  };

  useEffect(() => {
    fillMedianScores(2);

    let prevSorted = [];

    let prevSpecialList = [];

    students.map((student) =>
      prevSorted.push({
        name: student.name,
        score: student.score ? student.score : 0,
        image: student.image ? true : false,
        special: student.special,
      })
    );

    setSorted(prevSorted);

    students.map((student) =>
      student.special ? prevSpecialList.push(student) : null
    );

    setSpecialList(prevSpecialList);

    if (sort === "рейтингу") {
      setSorted((prev) => {
        return prev
          .sort((a, b) => b.score - a.score)
          .filter((student) => !student.special);
      });
    } else {
      setSorted((prev) => {
        return prev
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((student) => !student.special);
      });
    }
  }, [final, student, sort, scores, students]);

  useEffect(() => {
    let list = "";
    setListToCopy(() => {
      sorted.map((student, i) => {
        list =
          list +
          `${i + 1}. ${student.name.split(" ")[0]}  ${
            student.name.split(" ")[1]
          }` +
          ": " +
          student.score;
        if (sort === "рейтингу") {
          if (i === 0) list = list + "   (I степень)\n";
          else if (i === 1 || i === 2) list = list + "   (II степень)\n";
          else if (i === 3 || i === 4 || i === 5)
            list = list + "   (III степень)\n";
          else list = list + "\n";
        } else list = list + "\n";
      });

      if (specialList.length) {
        list = list + "\n" + "Специальные номинации:\n";
        specialList.map((student, i) => {
          list =
            list +
            `${sorted.length + i + 1}. ${student.name.split(" ")[0]}  ${
              student.name.split(" ")[1]
            }` +
            ": " +
            student.score +
            "\n";
        });
      }

      return list;
    });
  }, [sorted]);

  const copy = (e) => {
    e.preventDefault();
    copyText.select();
    document.execCommand("copy");
  };

  const truncateUrl = (e) => {
    e.preventDefault();

    students.map((student) => {
      let clearedName = decodeURI(student.image);

      if (student.image && student.image.includes("https")) {
        clearedName = student.image.split("/o/")[1];
        clearedName = clearedName.split("?alt")[0];
      }

      // console.log(clearedName);

      db.ref(
        currentGroup.dbName +
          "/group/" +
          student.id +
          "/projects/" +
          currentProject.name
      ).update({ image: clearedName, imageThumb: "thumb_" + clearedName });
    });
  };

  const metaTrigger = (e) => {
    e.preventDefault();
    // students.map(student => {
    if (student.image) {
      let file = storage.ref().child(student.image);
      // console.log(student.image)

      let newMetadata = {
        customMetadata: {
          Service: "triggered",
        },
      };

      file
        .updateMetadata(newMetadata)
        .then((metadata) => {
          // console.log(student.name, metadata);
        })
        .catch((error) => {
          // console.log(student.name, error);
        });
    }
    // })
  };

  const projectListMutation = (e) => {
    e.preventDefault();
    // console.log(currentGroup.projects);
    let newProjects = [];
    currentGroup.projects.map((project) => {
      // console.log(project);
      let newProject = {};
      newProject.name = project;
      if (
        project === "Архитектурная бионика" ||
        project === "Компьютерные технологии" ||
        project === "Поселок" ||
        project === "Индивидуальный жилой дом"
      ) {
        newProject.sliders = false;
      } else {
        newProject.sliders = true;
      }
      newProjects.push(newProject);
    });

    console.log(newProjects);

    db.ref(currentGroup.dbName).update({ projects: newProjects });
  };

  const createProject = (name, sliders) => {
    // console.log(currentGroup);
    console.log(name, sliders);

    let projects = currentGroup.projects;
    let project = {};
    project.name = name;
    project.sliders = sliders;
    projects.push(project);

    db.ref(currentGroup.dbName).update({ projects });
  };

  const list = [
    { name: "Аввад Риад Али_Рияд", id: 191009 },
    { name: "Бадиа Мохаммед Реда", id: 191010 },
    { name: "Белякова Дарья Дмитриевна", id: 180234 },
    { name: "Бурина Валентина Сергеевна", id: 190743 },
    { name: "Бычкова Валерия Михайловна", id: 190076 },
    { name: "Груданова Ксения Дмитриевна", id: 190745 },
    { name: "Егорова Екатерина Олеговна", id: 190747 },
    { name: "Ефимычева Валерия Андреевна", id: 190080 },
    { name: "Исмаил Али_Зейн Эль_Аабдин", id: 191013 },
    { name: "Крюков Дмитрий Сергеевич", id: 190749 },
    { name: "Малышкина Виктория Сергеевна", id: 190379 },
    { name: "Мешалкина Екатерина Евгеньевна", id: 190086 },
    { name: "Мишинова Ангелина Валерьевна", id: 190087 },
    { name: "Сумина Юлия Анатольевна", id: 190360 },
    { name: "Тюкина Татьяна Викторовна", id: 190089 },
    { name: "Тюкина Юлия Викторовна", id: 190361 },
    { name: "Тюрина Анастасия Александровна", id: 190243 },
    { name: "Угодчикова Дарья Евгеньевна", id: 190755 },
    { name: "Федотов Евгений Александрович", id: 190756 },
    { name: "Шватова Валерия Сергеевна", id: 190246 },
    { name: "Эль_Мезуари Иссамеддин", id: 191018 },
    { name: "Эль_Харруби Амина", id: 191019 },
  ];

  const listTwo = [
    { name: "Анисимова Екатерина Андреевна", id: 190742 },
    { name: "Бусламти Анас", id: 191011 },
    { name: "Воробкин Егор Иванович", id: 190077 },
    { name: "Высотина Мария Андреевна", id: 190744 },
    { name: "Диаките Гауссу", id: 191012 },
    { name: "Евдокимова Мария Вадимовна", id: 190746 },
    { name: "Ивченко Елизавета Дмитриевна", id: 190235 },
    { name: "Карева Ирина Владимировна", id: 190083 },
    { name: "Кассу Маруан", id: 180655 },
    { name: "Катраева Нина Сергеевна", id: 190084 },
    { name: "Кузнецов Никита Дмитриевич", id: 190750 },
    { name: "Лейман Елизавета Алексеевна", id: 190238 },
    { name: "Медяник Екатерина Владимировна", id: 190752 },
    { name: "Пиманкина Арина Артемовна", id: 190088 },
    { name: "Сарасонов Денис Игоревич", id: 190240 },
    { name: "Смирнова Полина Дмитриевна", id: 190241 },
    { name: "Удалова Елизавета Александровна", id: 190244 },
    { name: "Шакиб Фатима-Эззахра", id: 191016 },
    { name: "Шиксвамени_Панду Че_Гевара Кантема", id: 191017 },
    { name: "Шипкова Екатерина Эдуардовна", id: 190247 },
    { name: "Эль Ямани Ахлам", id: 191020 },
  ];

  const fillGroup = (e) => {
    e.preventDefault();
    listTwo.forEach((student) => {
      db.ref(`054/group/${student.id}`).update({
        name: student.name,
        gender: false,
        id: student.id,
        group: "054",
      });
    });
  };

  return (
    <>
      {modalCreateProject && (
        <ModalCreateProject
          createProject={createProject}
          setModalCreateProject={setModalCreateProject}
        />
      )}
      {student.id ? (
        <div className="evaluator">
          <div className="prof-row">
            {`Преподаватель: `}
            <div className="prof-block bold">
              {`${user.displayName.split(" ")[0]} ${user.displayName
                .split(" ")[1]
                .charAt(0)}.${
                user.displayName.split(" ")[2]
                  ? user.displayName.split(" ")[2].charAt(0)
                  : ""
              }`}
            </div>
          </div>

          <div className="ev-body">
            <div className="title-group">
              {`Группа ${currentGroup.name} - сдали ${
                students.filter((student) => student.image).length
              } из ${students.length}
                        (${(
                          (students.filter((student) => student.image).length /
                            students.length) *
                          100
                        ).toFixed(1)}%)`}
            </div>
            <div className="title">{student.name}</div>

            {student.image ? (
              <div className="score-block">
                {/* <div className='values bleak'>
                        <div>{`Среднее значение`}</div>
                        <div>{final.toFixed(2)}</div>
                    </div> */}
                {score ? (
                  <div className="values">
                    <div className="values-box">{`Ваша оценка:`}</div>
                    {currentProject.sliders ? (
                      <div className="bold">
                        {`${parseFloat(idea).toFixed(2)} / ${parseFloat(
                          norms
                        ).toFixed(2)} / ${parseFloat(render).toFixed(2)}`}
                      </div>
                    ) : (
                      <div className="bold">
                        {`${parseFloat(idea).toFixed(2)}`}
                      </div>
                    )}
                    {/* <div className='bold'>
                            {(score ? parseFloat(score).toFixed(2) : '0.00')}
                        </div> */}
                  </div>
                ) : (
                  <div className="values warning">
                    <div>
                      {`${
                        user.displayName.split(" ")[1]
                      }! Для выставления оценки воспользуйтесь слайдерами`}
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>

          {student.image ? (
            <div className="form-container">
              <Slider
                initial={idea}
                changeUpState={(idea) => {
                  setIdea(idea);
                  !currentProject.sliders && setNorms(idea);
                  !currentProject.sliders && setRender(idea);
                }}
                id={currentProject.sliders ? "Идея:" : "Оценка:"}
              />
              {currentProject.sliders && (
                <Slider
                  initial={norms}
                  changeUpState={(norms) => setNorms(norms)}
                  id={"Грамотность:"}
                />
              )}
              {currentProject.sliders && (
                <Slider
                  initial={render}
                  changeUpState={(render) => setRender(render)}
                  id={"Подача:"}
                />
              )}

              <div
                className="special"
                onMouseUp={(e) => {
                  e.preventDefault();
                  // setCheck(check ? false : true)
                  setSpecial(special ? false : true);
                  db.ref(
                    currentGroup.dbName +
                      "/group/" +
                      student.id +
                      "/projects/" +
                      currentProject.name
                  ).update({ special: special ? false : true });
                }}
              >
                {special ? "вернуть в рейтинг" : "исключить из рейтинга"}
              </div>
            </div>
          ) : (
            <div className="form bold">{"Студент не загрузил проект"}</div>
          )}
          <div
            style={{
              marginTop: "2vh",
              cursor: "pointer",
              marginBottom: "10px",
            }}
            className="bigger"
            onClick={() => setShowScores(showScores ? false : true)}
          >
            <div style={{ fontSize: "20px" }}>Список группы</div>
            {(!showScores ? "(показать баллы)" : "(скрыть баллы)") + ":"}
          </div>
          <div className="list-header">
            <div>{"Сортировать по:"}</div>
            <div className="sort_selector">
              {tabs.map((tab) => (
                <div
                  className={"clickable " + (tab.bold ? "bold" : null)}
                  key={tab.key}
                  onClick={() => {
                    setActiveTab(tab.key);
                    setSort(tab.role);
                  }}
                  style={{ color: tab.color }}
                >
                  {tab.role}
                </div>
              ))}
            </div>
          </div>
          <div className="list-wrapper">
            <div className="listcontainer">
              {sorted.map((student, i) => (
                <div
                  className={
                    "list-item " +
                    (sort === "рейтингу"
                      ? i === 0 || i === 2 || i === 5
                        ? "separator"
                        : " "
                      : " ")
                  }
                  style={{
                    fontWeight:
                      sort === "рейтингу" && +student.score > 0
                        ? i === 0
                          ? "900"
                          : i === 1 || i === 2
                          ? "700"
                          : i === 3 || i === 4 || i === 5
                          ? "500"
                          : "100"
                        : "100",
                    color: student.image ? "black" : "silver",
                  }}
                  key={i}
                >
                  {student.name.split(" ")[0] +
                    " " +
                    student.name.split(" ")[1] +
                    " " +
                    (showScores ? student.score : "")}
                </div>
              ))}
              {specialList.length && sort === "рейтингу" ? (
                <div
                  style={{
                    marginTop: "2vh",
                    marginBottom: "1vh",
                    fontWeight: "bold",
                  }}
                >
                  {"Вне рейтинга:"}
                  {specialList.map((student) => (
                    <div className={"list-item"}>
                      {student.name.split(" ")[0] +
                        " " +
                        student.name.split(" ")[1] +
                        " " +
                        (showScores ? student.score : "")}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          <div className="ev-buttons">
            <div
              className="btn-light"
              title="Отозвать оценку"
              onMouseUp={(e) => (score ? unScore(e) : null)}
            >
              <FontAwesomeIcon icon={faUndoAlt} size="1x" className="icon" />
            </div>

            <div
              className="btn-light"
              title="Выгрузить ведомость"
              onMouseUp={(e) => save(e)}
            >
              <FontAwesomeIcon icon={faFileWord} size="1x" className="icon" />
            </div>

            <div
              className="btn-light"
              title="Скопировать результаты"
              onMouseUp={(e) => copy(e)}
            >
              <FontAwesomeIcon icon={faCopy} size="1x" className="icon" />
            </div>

            {/* <div
              className="btn-light"
              title="Статистика"
              onMouseUp={(e) => setShowStats(showStats ? false : true)}
            >
              <FontAwesomeIcon icon={faSignal} size="1x" className="icon" />
            </div> */}

            {/* <div className='btn-light' title='Moodle groups' type='submit' form='addUser' onMouseUp={e => getAllGroupsFromMoodle(e)}>
                    <FontAwesomeIcon icon={faDiceD6} size='1x' className='icon'/>
                </div>

                <div className='btn-light' title='Moodle students of group' type='submit' form='addUser' onMouseUp={e => getStudentsOfGroup(e, 28)}>
                    <FontAwesomeIcon icon={faDiceD6} size='1x' className='icon'/>
                </div>

                <div className='btn-light' title='Moodle student' type='submit' form='addUser' onMouseUp={e => getStudentFromMoodle(e, 648)}>
                    <FontAwesomeIcon icon={faDiceD6} size='1x' className='icon'/>
                </div>

                <div className='btn-light' title='Service button' type='submit' form='addUser' onMouseUp={e => truncateUrl(e)}>
                    <FontAwesomeIcon icon={faCog} size='1x' className='icon'/>
                </div> */}

            <div
              className="btn-light"
              title="Создать новый проект"
              type="submit"
              form="addUser"
              onMouseUp={() => setModalCreateProject(true)}
            >
              <FontAwesomeIcon icon={faLandmark} size="1x" className="icon" />
            </div>

            {/* <div className='btn-light' title='Meta trigger' type='submit' form='addUser' onMouseUp={e => fillGroup(e)}>
                    <FontAwesomeIcon icon={faCog} size='1x' className='icon'/>
                </div> */}

            <div
              className="btn-light"
              title="Выход"
              onMouseUp={(e) => {
                e.preventDefault();
                auth.signOut();
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} size="1x" className="icon" />
            </div>
          </div>
          <textarea
            className="list"
            readOnly
            ref={(ref) => (copyText = ref)}
            value={lstToCopy}
          />
        </div>
      ) : null}
    </>
  );
};
