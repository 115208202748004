import React, { useState, useEffect } from 'react'
import { DUMMY } from './right'


export const GalleryImage = ({ image }) => {

   const [loaded, setLoaded] = useState(false)

   useEffect(() => setLoaded(false),[image])

   return (
            <>
            {loaded ? null : <div className='loading-img'><div className='lds-dual-ring'></div></div>}
            <img src={image ? image : DUMMY} onLoad={() => setLoaded(true)} 
            className={image ? 'gallery-img' : 'gallery-cell-dum'}  style={{visibility: loaded ? 'visible' : 'hidden'}}/>
            </>   
   )
}
