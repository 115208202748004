import React, { useState, useEffect } from "react";
import "./App.css";
import { auth } from "./config";
import { Left } from "./left";
import { Right } from "./right";
import ContextProvider from "./context";

const useAuth = () => {
  const fireUser = auth.currentUser;
  const [user, setUser] = useState(fireUser);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      user ? setUser(user) : setUser(null);
    });
    return () => {
      unsub();
    };
  }, []);
  return user;
};

function App() {
  const user = useAuth();

  return (
    <ContextProvider>
      <div className="container">
        <Left user={user} />
        <Right user={user} />
      </div>
    </ContextProvider>
  );
}

export default App;
