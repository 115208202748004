import React from 'react'
import './modal.css'

export const Modal = ({ removeImage, setModalDelete, student }) => {

    return (
        <div className='back-drop'>
            <div className='modal-container'>
                <div className='bold'>{`Вы удаляете только проект!`}</div>
                <div>{'Студент: ' + student.name}</div>
                <div className='small'>{'Оценка удалятся кнопкой "Отозвать оценку" внизу слева'}</div>
                <div className='modal-inner'>
                    <div className='modal-btn del' onClick={e => {
                        e.preventDefault()
                        setModalDelete(false)
                        removeImage()
                        }}>
                        {'Удалить'}
                    </div>
                    <div className='modal-btn' onClick={e => {
                        e.preventDefault()
                        setModalDelete(false)
                        }}>
                        {'Отменить'}
                    </div>
                </div>
            </div>
        </div>
    )
}

 
