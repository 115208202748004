import React, { useState, useEffect } from "react";

export const List = ({ students }) => {
  const [sorted, setSorted] = useState([]);

  useEffect(() => {
    let prevSorted = [];

    students.map((student) =>
      prevSorted.push({
        name: student.name,
        score: student.score ? student.score : 0,
        image: student.image ? true : false,
      })
    );

    setSorted(prevSorted);

    setSorted(() => prevSorted.sort((a, b) => b.score - a.score));
  }, [students]);

  return (
    <div className="listcontainer">
      {sorted.map((student, i) => (
        <div
          className={"list-item "}
          style={{
            fontWeight:
              +student.score > 0
                ? i === 0
                  ? "900"
                  : i === 1 || i === 2
                  ? "700"
                  : i === 3 || i === 4 || i === 5
                  ? "500"
                  : "100"
                : "100",
            color: student.image ? "black" : "silver",
          }}
          key={i}
        >
          {student.name.split(" ")[0] +
            " " +
            student.name.split(" ")[1] +
            " " +
            student.score}
        </div>
      ))}
    </div>
  );
};
