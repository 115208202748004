export const wordstyles = [
    {
        id: "italic",
        name: "italic",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
            size: 20,
            bold: false,
            italics: true,
        },
        paragraph: {
            spacing: {
                before: 20,
                after: 20
            },
        },    
    },
    {
        id: "footer",
        name: "footer",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
            size: 18,
            bold: false,
            italics: false,
        },
        paragraph: {
            spacing: {
                line: 200,
                before: 240,
            },
            indent: {
                firstLine: 720,
            },  
        }  
    },
    {
        id: "bigger",
        name: "bigger",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
            size: 22,
            bold: false,
            italics: false,
        },
        paragraph: {
            spacing: {
                // line: 200,
                before: 50,
            },
            indent: {
                left: 160,
                // right: 160
            },  
        },
    },
    {
            id: "big",
            name: "big",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
                size: 20,
                bold: false,
                italics: false,
            },
            paragraph: {
                spacing: {
                    before: 180,
                    after: 180
                },
                indent: {
                    left: 160,
                    right: 160
                },  
                
            }
    },
    {
        id: "big-italic",
        name: "big-italic",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
            size: 22,
            bold: false,
            italics: true,
        },
        paragraph: {
            spacing: {
                before: 50,
                after: 50
            },
            indent: {
                left: 160,
                right: 160
            },  
            
        }
}    
        
  ]