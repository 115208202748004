import { saveAs } from 'file-saver'
import { Document, Packer, Paragraph, Table, TableCell, TableRow, WidthType, AlignmentType, BorderStyle, VerticalAlign, TextRun } from 'docx'
import { wordstyles } from './wordstyles'


let date = `${new Date().getDate()}.${new Date().getMonth() + 1}.${new Date().getFullYear()}`

export const saveDOC = ( students, currentGroup, project, hours ) => {
   

    const doc = new Document({
      creator: 'А.В. Гребенников',
      title: `Оценочная ведомость для группы ${currentGroup}`,
      description:  `Заполнено автоматически`,
      styles: {
        paragraphStyles: wordstyles
        }
    })

  const chair = new Paragraph({
    children: [
      new TextRun({
      text: 'кафедра дизайна архитектурной среды',
      italics: true
      })],
    alignment: AlignmentType.RIGHT,
    spacing: {
      after: 160
    }
    })

  const university = new Paragraph({
    text: 'НИЖЕГОРОДСКИЙ ГОСУДАРСТВЕННЫЙ АРХИТЕКТУРНО-СТРОИТЕЛЬНЫЙ УНИВЕРСИТЕТ ФАиД',
    alignment: AlignmentType.CENTER,
    indent: {
      left: 600,
      right: 600
    }
  })

  const caps = new Paragraph({
    children: [
      new TextRun({
      text: 'ЗАЧЕТНАЯ ВЕДОМОСТЬ',
      bold: true,
      size: 26
    })],
    alignment: AlignmentType.CENTER
  })

  const years = new Paragraph({
    children: [
      new TextRun({
      text: '2020/2021 учебный год',
      bold: true,
      size: 26
    })],
    alignment: AlignmentType.CENTER
  })

  let headtabledata = [
    new TableRow ({
        children: [
            new TableCell({
            children: [new Paragraph({
                text: 'Направление подготовки (специальность), код:',
                style: 'bigger',
                })],
            
            verticalAlign: VerticalAlign.BOTTOM,
            borders: {
              bottom: { style: BorderStyle.NONE, size: 1, color: 'black' },
              right: { style: BorderStyle.NONE, size: 0, color: 'black' },
              top: { style: BorderStyle.NONE, size: 0, color: 'black' },
              left: { style: BorderStyle.NONE, size: 0, color: 'black' }
            }
            }),
            new TableCell({
                children: [new Paragraph({
                    text: '07.03.03 Дизайн архитектурной среды',
                    style: 'big-italic',
                    })],
                verticalAlign: VerticalAlign.BOTTOM,
                columnSpan: 3,
                borders: {
                  bottom: { style: BorderStyle.SINGLE, size: 1, color: 'black' },
                  right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  left: { style: BorderStyle.NONE, size: 0, color: 'black' }
                }
                })
            
        ]

    }),
    new TableRow ({
        children: [
            new TableCell ({
                children: [
                  new Paragraph({
                    text: 'Направленность',
                    style: 'bigger',
                    spacing: {
                      after: 0
                    }
                }),
                  new Paragraph({
                    text: ' (профиль, специализация):',
                    style: 'bigger',
                    spacing: {
                      before: 0
                    },
                    indent: {
                      left: 80
                    }
                  })
              ],
                verticalAlign: VerticalAlign.BOTTOM,
                borders: {
                  bottom: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  left: { style: BorderStyle.NONE, size: 0, color: 'black' }
                }
            }),
            new TableCell ({
                children: [new Paragraph({
                    text: 'Дизайн архитектурной среды',
                    style: 'big-italic',
                })],
                verticalAlign: VerticalAlign.BOTTOM,
                columnSpan: 3,
                borders: {
                  bottom: { style: BorderStyle.SINGLE, size: 1, color: 'black' },
                  right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  left: { style: BorderStyle.NONE, size: 0, color: 'black' }
                }
            })
        ]
    }),
    new TableRow ({
        children: [
            new TableCell ({
                children: [new Paragraph({
                    text: `Группа: ${currentGroup}`,
                    style: 'bigger',
                })],
                width: {
                  size: 3000,
                  type: WidthType.DXA
                  },
                verticalAlign: VerticalAlign.BOTTOM,
                borders: {
                  bottom: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  left: { style: BorderStyle.NONE, size: 0, color: 'black' }
                }
            }),
            new TableCell ({
                children: [new Paragraph({
                    text: 'Курс:  4',
                    style: 'bigger',
                })],
                width: {
                  size: 2000,
                  type: WidthType.DXA
                  },
                verticalAlign: VerticalAlign.BOTTOM,
                borders: {
                  bottom: { style: BorderStyle.SINGLE, size: 1, color: 'black' },
                  right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  left: { style: BorderStyle.NONE, size: 0, color: 'black' }
                }
            }),
            new TableCell ({
                children: [new Paragraph({
                    text: 'Семестр: 7',
                    style: 'bigger',
                })],
                width: {
                  size: 2000,
                  type: WidthType.DXA
                  },
                verticalAlign: VerticalAlign.BOTTOM,
                borders: {
                  bottom: { style: BorderStyle.SINGLE, size: 1, color: 'black' },
                  right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  left: { style: BorderStyle.NONE, size: 0, color: 'black' }
                }
            }),
            new TableCell ({
                children: [new Paragraph({
                    text: 'Полусеместр:',
                    style: 'bigger',
                })],
                width: {
                  size: 2200,
                  type: WidthType.DXA
                  },
                verticalAlign: VerticalAlign.BOTTOM,
                borders: {
                  bottom: { style: BorderStyle.SINGLE, size: 1, color: 'black' },
                  right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                  left: { style: BorderStyle.NONE, size: 0, color: 'black' }
                }
            }),
        ]
    }),
    new TableRow ({
      children: [
          new TableCell ({
              children: [new Paragraph({
                  text: 'Дисциплина:',
                  style: 'bigger',
              })],
              verticalAlign: VerticalAlign.BOTTOM,
              borders: {
                bottom: { style: BorderStyle.NONE, size: 0, color: 'black' },
                right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                left: { style: BorderStyle.NONE, size: 0, color: 'black' }
              }
          }),
          new TableCell ({
              children: [new Paragraph({
                  text: `Архитектурно-дизайнерское проектирование (${hours})`,
                  style: 'big-italic',
              })],
              verticalAlign: VerticalAlign.BOTTOM,
              columnSpan: 3,
              borders: {
                bottom: { style: BorderStyle.SINGLE, size: 1, color: 'black' },
                right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                left: { style: BorderStyle.NONE, size: 0, color: 'black' }
              }
          })
      ]
    }),
    new TableRow ({
      children: [
          new TableCell ({
              children: [new Paragraph({
                  text: 'Преподаватель:',
                  style: 'bigger',
              })],
              verticalAlign: VerticalAlign.BOTTOM,
              borders: {
                bottom: { style: BorderStyle.NONE, size: 0, color: 'black' },
                right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                left: { style: BorderStyle.NONE, size: 0, color: 'black' }
              }
          }),
          new TableCell ({
              children: [new Paragraph({
                  text: 'ст. преп. Гребенников А.В',
                  style: 'big-italic',
              })],
              verticalAlign: VerticalAlign.BOTTOM,
              columnSpan: 2,
              borders: {
                bottom: { style: BorderStyle.SINGLE, size: 1, color: 'black' },
                right: { style: BorderStyle.NONE, size: 0, color: 'black' },
                top: { style: BorderStyle.NONE, size: 0, color: 'black' },
                left: { style: BorderStyle.NONE, size: 0, color: 'black' }
              }
          }),
          new TableCell ({
            children: [new Paragraph({
                text: `Дата: ${date}`,
                style: 'big-italic',
            })],
            verticalAlign: VerticalAlign.BOTTOM,
            borders: {
              bottom: { style: BorderStyle.SINGLE, size: 1, color: 'black' },
              right: { style: BorderStyle.NONE, size: 0, color: 'black' },
              top: { style: BorderStyle.NONE, size: 0, color: 'black' },
              left: { style: BorderStyle.NONE, size: 0, color: 'black' }
            }
          })
      ]
  })
]

    let parag = [new TableRow ({
        children: [
          new TableCell ({
            children: [new Paragraph({
              text: '№ п/п',
              style: 'big',
              alignment: AlignmentType.CENTER,
            })],
            width: {
              size: 100,
              type: WidthType.DXA
            }
          }),
          new TableCell ({
            children: [new Paragraph({
              text: 'Фамилия, имя, отчество студента',
              style: 'big',
              alignment: AlignmentType.CENTER
            })],
            width: {
              size: 5000,
              type: WidthType.DXA
            }
          }),
          new TableCell ({
            children: [new Paragraph({
              text: '№ зачетной книжки',
              style: 'big',
              alignment: AlignmentType.CENTER
            })],
            width: {
              size: 1500,
              type: WidthType.DXA
            }
          }),
          new TableCell ({
            children: [new Paragraph({
              text: 'Балл',
              style: 'big',
              alignment: AlignmentType.CENTER
            })],
            width: {
              size: 1000,
              type: WidthType.DXA
            }
          }),
          new TableCell ({
            children: [new Paragraph({
              text: 'Оценка',
              style: 'big',
              alignment: AlignmentType.CENTER
            })],
            width: {
              size: 2000,
              type: WidthType.DXA
            }
          }),
          new TableCell ({
            children: [new Paragraph({
              text: 'Подпись преподавателя',
              style: 'big',
              alignment: AlignmentType.CENTER
            })],
            width: {
              size: 20,
              type: WidthType.AUTO
            }
          })
        ]
        
      })
    ]

    students
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((student, i) => { 
      let index = i + 1
      let scoreText = ''
      if(parseFloat(student.score) >= 4.50) scoreText = 'отлично'
      else if(parseFloat(student.score) >= 3.50 && parseFloat(student.score) < 4.50) scoreText = 'хорошо'
      else if(parseFloat(student.score) >= 2.50 && parseFloat(student.score) < 3.50) scoreText = 'удовлетворительно'
      else if(parseFloat(student.score) > 0 && parseFloat(student.score) < 2.50) scoreText = 'неудовлетворительно'
      else scoreText = 'не явил' + (student.gender ? 'ся' : 'ась')
      parag.push(
      new TableRow ({
        children: [
          new TableCell ({
            children: [new Paragraph({
              text: index.toString(),
              alignment: AlignmentType.CENTER
            })],
            verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell ({
            children: [new Paragraph({
              text: student.name,
              indent: {
                left: 100
              }
            })],
            verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell ({
            children: [new Paragraph({
              text: student.id.toString(),
              alignment: AlignmentType.CENTER
            })],
            verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell ({
            children: [new Paragraph({
              text: +student.score ? student.score.toString() + '0' : '----',
              alignment: AlignmentType.CENTER,
            })],
            verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell ({
            children: [new Paragraph({
              text: scoreText,
              alignment: AlignmentType.CENTER,
              style: 'italic'
            })],
            verticalAlign: VerticalAlign.CENTER
          }),
          new TableCell ({
            children: []
          }),
        ]
      })
    )})

    parag.push(
      new TableRow ({
        children: [
        new TableCell ({
          children: [
            new Paragraph({
              text: `Декан факультета`,
              style: 'bigger'
            }),
            new Paragraph({
              text: `(директор института) _____________________`,
              spacing: {
                before: 50
              },
              indent: {
                left: 160
              }
            }), 
            new Paragraph({
              text: `${date} г.`,
              alignment: AlignmentType.RIGHT,
              style: 'bigger',
              indent: {
                right: 160
              }
            })
          ],
          columnSpan: 2
          }),
          new TableCell ({
            children: [
              new Paragraph({
                text: `Итого:	"отлично" ${students.filter(student => parseFloat(student.score) >= 4.50).length} чел.`,
                alignment: AlignmentType.RIGHT,
                spacing: {
                  before: 160
                }
              }),
              new Paragraph({
                text: `"хорошо" ${students.filter(student => (parseFloat(student.score) >= 3.50 && parseFloat(student.score) < 4.50)).length} чел.`,
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                text: `"удовлетворительно" ${students.filter(student => (parseFloat(student.score) >= 2.50 && parseFloat(student.score) < 3.50)).length} чел.`,
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                text: `"неудовлетворительно" ${students.filter(student => (parseFloat(student.score) > 0 && parseFloat(student.score) < 2.50)).length} чел.`,
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                text: `"не аттестовано" ${students.filter(student => !parseFloat(student.score)).length} чел.`,
                alignment: AlignmentType.RIGHT,
              }),
              new Paragraph({
                text: `Заведующий кафедрой ___________________________`,
                style: 'bigger'
              }),
              new Paragraph({
                text: '(сокращенное наименование кафедры и подпись)',
                alignment: AlignmentType.RIGHT
              })
            
          ],
            columnSpan: 4,
            borders: {
              bottom: { style: BorderStyle.NONE, size: 0, color: 'black' },
              right: { style: BorderStyle.NONE, size: 0, color: 'black' }
            }
          })
        ]
      })
    )

    const table = new Table({
        rows: parag,
        width: {
          size: 10000,
          type: WidthType.DXA,
          }     
    })

    const headtable = new Table({
      rows: headtabledata,
        width: {
          size: 10000,
          type: WidthType.DXA,
          }     
    })

    const footer = new Paragraph({
      text: 'Представительская и фамильная части ведомости заполняются в деканате факультета (дирекции института), подписываются деканом (директором), после чего ведомость передается на кафедру (экзаменатору). Результаты ответа студента выставляются в баллах с переводом в словесную оценку ("отлично", "хорошо", "удовлетворительно", "неудовлетворительно"). По окончании зачёта оформленная ведомость кафедрой возвращается в деканат факультета (дирекцию института).',
      style: 'footer',
      alignment: AlignmentType.JUSTIFIED,
      
    })

    const creationTime = new Paragraph({
      children: [
        new TextRun({
          text: `${new Date().getHours()}:${new Date().getMinutes()} ${date}`,
          italics: true,
          size: 16 
        })
      ],
      alignment: AlignmentType.RIGHT
    })

    doc.addSection({
      children: [ chair, university, caps, years, headtable, new Paragraph(''), table, footer, creationTime ],
      margins: {
        top: 0,
        right: 1 / 2.54 * 72 * 20,
        bottom: 0.5 / 2.54 * 72 * 20,
        left: 2.5 / 2.54 * 72 * 20,
    }, 
    })
  
    Packer.toBlob(doc).then(blob => {
         saveAs(blob, `оценки ${currentGroup}.docx`)
       })
  }