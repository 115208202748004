import React, { useContext, useEffect, useState } from 'react'
import { Context } from './context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import './modal.css'

export const Stats = ({ changeUpState }) => {

    const { allOfThem } = useContext(Context)

    const [men, setMen] = useState([])
    const [women, setWomen] = useState([])

    useEffect(() => {

        setMen(allOfThem.filter(student => student.gender))
        setWomen(allOfThem.filter(student => !student.gender))

    },[])

    const percentage = (total, number) => {
        let result = number/total*100
        return result.toFixed(2)
    }
   
    return (
        <div className='back-drop'>
            <div className='modal-container'>
                <div className='modal-x'
                        onClick={e => {
                            e.preventDefault()
                            changeUpState(false)
                        }}>
                <FontAwesomeIcon icon={faTimesCircle} size='1x' color='rgb(50,50,50)'/>
                </div>
                {'статистика'}
                    <div>{'Архитекторы: ' + men.length + ' чел / ' + percentage(allOfThem.length, men.length) + '%'}</div>
                    <div>{'Архитекторки: ' + women.length + ' чел / ' + percentage(allOfThem.length, women.length) + '%'}</div>
                    {/* <div>{'Сдали: ' + allOfThem.filter(student => student.projects.Архитектурная бионика.image).length}</div> */}
            </div>
        </div>
    )
}

 