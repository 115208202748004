import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/database'
// import 'firebase/functions'


let config = {
    apiKey: "AIzaSyCpW9ICzaXbur6tCj35Vk33c_NXnbtl6Ik",
    authDomain: "evaluator-ceb45.firebaseapp.com",
    databaseURL: "https://evaluator-ceb45-f3ece.europe-west1.firebasedatabase.app",
    projectId: "evaluator-ceb45",
    storageBucket: "evaluator-ceb45.appspot.com",
    messagingSenderId: "552657422595",
    appId: "1:552657422595:web:34aecb37267d53dcba4136"
  }

// const config = {
//     apiKey: "AIzaSyCpW9ICzaXbur6tCj35Vk33c_NXnbtl6Ik",
//     authDomain: "evaluator-ceb45.firebaseapp.com",
//     databaseURL: "https://evaluator-ceb45-e9cac.firebaseio.com",
//     projectId: "evaluator-ceb45",
//     storageBucket: "evaluator-ceb45.appspot.com",
//     messagingSenderId: "552657422595",
//     appId: "1:552657422595:web:34aecb37267d53dcba4136"
//   }

if (!firebase.apps.length) {
  firebase.initializeApp(config)
}

export const db = firebase.database()
export const storage = firebase.storage()
export const auth = firebase.auth()
// export const func = firebase.functions()

export const MoodleToken = `29fdbc95df60370fd6d0cf3839867e7b`
