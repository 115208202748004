import React, { useState, useEffect, createContext } from "react";
import { db } from "./config";

export const Context = createContext();

const ContextProvider = (props) => {
  const [students, setStudents] = useState([{ id: 0, name: " ", image: " " }]);
  const [student, setStudent] = useState(students[0]);
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState({
    dbName: "",
    name: "",
    projects: [{ name: "", sliders: true }],
  });
  const [current, setCurrent] = useState(0);
  const [currentProject, setCurrentProject] = useState({
    name: "",
    sliders: false,
  });
  const [scores, setScores] = useState(new Map());
  const [special, setSpecial] = useState(false);

  const [allOfThem, setAllOfThem] = useState([]);
  const [showStats, setShowStats] = useState(false);

  const [gallery, setGallery] = useState(false);

  const readGroups = () => {
    let groups = [];
    let all = [];

    db.ref().once("value", (snapshot) => {
      let dbNames = Object.keys(snapshot.val());
      let data = Object.values(snapshot.val());
      data.map((group, i) =>
        groups.push({
          dbName: dbNames[i],
          name: group.alias,
          projects: group.projects,
          hours: group.hours,
        })
      );

      setGroups(groups);
      setCurrentGroup(groups[0]);

      data.map((group) => {
        let object = Object.values(group.group);
        // console.log(object)
        object.map((student) => all.push(student));
      });

      setAllOfThem(all);

      return groups;
    });

    return groups;
  };

  useEffect(() => readGroups(), []);

  useEffect(() => {
    currentGroup.projects && setCurrentProject(currentGroup.projects[0]);
  }, [currentGroup]);

  useEffect(() => {
    if (!currentProject.name) setCurrentProject(currentGroup.projects[0]);

    if (currentGroup.dbName) {
      db.ref(`/${currentGroup.dbName}/group`).on("value", (snapshot) => {
        let students = [];
        students = Object.values(snapshot.val());
        let studentsIds = Object.keys(snapshot.val());
        students.map((student, i) => (student.id = parseInt(studentsIds[i])));

        // console.log('Current project: ', currentProject)

        students.map((student) => {
          let projects = student.projects
            ? new Map(Object.entries(student.projects))
            : new Map();
          // console.log('First call: ', projects)
          student.image = projects.has(currentProject.name)
            ? Object.values(projects.get(currentProject.name))[0]
            : "";
          // console.log('Image: ', student.image)
          student.imageThumb = projects.has(currentProject.name)
            ? Object.values(projects.get(currentProject.name))[1]
            : "";
          let projectscores = projects.has(currentProject.name)
            ? Object.values(projects.get(currentProject.name))[2]
            : {};
          if (
            projects.has(currentProject.name) &&
            Object.values(projects.get(currentProject.name))[3]
          ) {
            student.special = Object.values(
              projects.get(currentProject.name)
            )[3];
          } else {
            student.special = false;
          }
          let scores = projectscores
            ? new Map(Object.entries(projectscores))
            : new Map();
          // console.log(scores)
          let final = 0.0;
          let finalFinal = 0.0;
          if (scores.size) {
            scores.forEach((value) => {
              final = final + (+value.idea + +value.norms + +value.render) / 3;
            });
          } else {
            final = 0.0;
          }
          if (scores.size) {
            finalFinal = final / scores.size;
          } else {
            finalFinal = 0.0;
          }

          student.score = finalFinal.toFixed(2);
        });

        setStudents(students.sort((a, b) => a.name.localeCompare(b.name)));
      });
    }
  }, [currentGroup, currentProject]);

  useEffect(() => {
    let projects = student.projects
      ? new Map(Object.entries(student.projects))
      : new Map();
    // student.image = projects.has(currentProject) ? Object.values(projects.get(currentProject))[0] : ''

    let projectscores = projects.has(currentProject.name)
      ? Object.values(projects.get(currentProject.name))[2]
      : {};
    setScores(
      projectscores ? new Map(Object.entries(projectscores)) : new Map()
    );
    if (
      projects.has(currentProject.name) &&
      Object.values(projects.get(currentProject.name))[3]
    ) {
      let special = Object.values(projects.get(currentProject.name))[3];
      setSpecial(special);
      console.log("On student change: ", special);
    } else {
      setSpecial(false);
    }
  }, [student, currentProject, special]);

  return (
    <Context.Provider
      value={{
        students,
        student,
        setStudent,
        groups,
        setCurrentGroup,
        allOfThem,
        showStats,
        setShowStats,
        special,
        setSpecial,
        currentGroup,
        current,
        setCurrent,
        currentProject,
        setCurrentProject,
        scores,
        setScores,
        gallery,
        setGallery,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default ContextProvider;
