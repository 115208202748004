import React, { useState, useContext } from "react";
import { auth } from "./config";
import { List } from "./list";
import { Context } from "./context";

export const Login = () => {
  const { students } = useContext(Context);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submitUser = (e) => {
    e.preventDefault();

    auth
      .signInWithEmailAndPassword(email, password)
      // auth.createUserWithEmailAndPassword(email, password).then((user) => {
      // user = auth.currentUser;
      // user
      //   .updateProfile({
      //     displayName: name,
      //     photoURL: "https://...",
      //   })
      .then(() => alert("ВЫ ВОШЛИ В СИСТЕМУ"))
      .catch((error) => {
        // alert(error.code)
        if (error.code === "auth/wrong-password") alert("НЕПРАВИЛЬНЫЙ ПАРОЛЬ");
        if (error.code === "auth/invalid-email")
          alert("НЕПРАВИЛЬНЫЙ АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ");
        if (error.code === "auth/user-not-found")
          alert("ПОЛЬЗОВАТЕЛЬ НЕ НАЙДЕН");
        if (error.code === "auth/user-disabled") alert("ПОЛЬЗОВАТЕЛЬ ОТКЛЮЧЕН");
      });
    // });
  };

  return (
    <div className="evaluator">
      <form className="login-form" onSubmit={(e) => submitUser(e)}>
        {/* <input
          className="prof-input"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        /> */}
        <div className="title-login">{"Вход для преподавателей"}</div>
        <div className="input_wrapper">
          <span>{"Адрес электронной почты:"}</span>
          <input
            type="text"
            className="prof-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input_wrapper">
          <span>{"Пароль:"}</span>
          <input
            type="password"
            autoComplete="current-password"
            className="prof-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <input type="submit" value="ОК" className="btn-light btn-wide" />
        <div className="list_for_students">
          <List students={students} />
        </div>
      </form>
    </div>
  );
};
