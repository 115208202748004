import React, { useContext } from "react";
import { Login } from "./login";

import { Evaluator } from "./evaluator";
import evaluator_logo from "./fonts/evaluator_logo.png";
import { Context } from "./context";

export const Left = ({ user }) => {
  const { allOfThem } = useContext(Context);

  return (
    <div className="left">
      <div className="ev-header">
        <img className="monkey" src={evaluator_logo} alt="Monkey To Evaluate" />
        {"Evaluator / студентов: " + allOfThem.length}
      </div>

      {user ? <Evaluator user={user} /> : <Login />}
    </div>
  );
};
