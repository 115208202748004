import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import './modal.css'

export const ModalUpload = ({ text, changeUpState }) => {

    let textToDisplay = ''

    if(text === 'upload') {
       textToDisplay = 'Подождите окончания загрузки...' 
    } else if(text === 'error') {
        textToDisplay = 'Можно загрузить только графические файлы форматов png и jpg'
    } else {
        textToDisplay = ''
    }
   
    return (
        <div className='back-drop'>
            <div className='modal-container'>
                <div className='modal-x'
                        onClick={e => {
                            e.preventDefault()
                            changeUpState(false)
                            }}>
                {text === 'error' &&
                <FontAwesomeIcon icon={faTimesCircle} size='1x' color='rgb(50,50,50)'/>}
                </div>
                {textToDisplay}
            </div>
        </div>
    )
}

 
