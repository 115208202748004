import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge } from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { db, storage, auth } from "./config";
import { Context } from "./context";
import { Gallery } from "./gallery";
import { Modal } from "./modal";
import { ModalUpload } from "./modalupload";
import { Stats } from "./stats";
import { Evaluator } from "./evaluator";
import { Login } from "./login";

export const DUMMY =
  "https://firebasestorage.googleapis.com/v0/b/evaluator-ceb45.appspot.com/o/dum-image.png?alt=media&token=61aca86b-cff5-4243-b8e9-8979c609eb66";
export const URL =
  "https://firebasestorage.googleapis.com/v0/b/evaluator-ceb45.appspot.com/o/";

export const Right = ({ user }) => {
  const { students } = useContext(Context);
  const { student, setStudent } = useContext(Context);
  const { groups } = useContext(Context);
  const { currentGroup, setCurrentGroup } = useContext(Context);
  const { current, setCurrent } = useContext(Context);
  const { currentProject, setCurrentProject } = useContext(Context);
  const { scores } = useContext(Context);
  const { showStats, setShowStats } = useContext(Context);
  const { gallery, setGallery } = useContext(Context);

  const [modal, setModal] = useState(false);

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [evaluators, setShowEvaluators] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [modalUploadText, setModalUploadText] = useState("");
  const [evaluatorMobile, setEvaluatorMobile] = useState(false);
  const [loaded, setLoaded] = useState(false);
  // const evaluatorMobile = true;
  useEffect(() => setLoaded(false), [student.image]);

  let evaluatorsList = [];

  useEffect(() => {
    setCurrent(0);
  }, [currentGroup]);

  useEffect(
    () =>
      (document.title =
        student.name.split(" ")[0] + " " + student.name.split(" ")[1]),
    [student]
  );

  useEffect(() => {
    evaluatorsList = Array.from(scores.keys());
    let values = Array.from(scores.values());
    let falseEvaluators = new Set();
    values.map((obj, i) => {
      if (obj.idea === 0 || obj.norms === 0 || obj.render === 0) {
        // console.log(obj, i);
        falseEvaluators.add(i);
      }
    });

    falseEvaluators.forEach((fal) => {
      evaluatorsList.filter((evaluator, i) => i !== fal);
    });
  }, [scores]);

  useEffect(() => {
    students.map((student, i) => (current === i ? setStudent(student) : null));
  }, [current, students]);

  const uploadPic = (e) => {
    e.preventDefault();
    // console.log(e.target.files[0]);
    setModalUploadText("upload");
    setModalUpload(true);

    let id = student.id;
    let group = currentGroup.dbName;
    let project = currentProject.name;
    let ext = e.target.files[0].name;
    let newName = project + "_" + id + "_" + group + "_" + ext;
    if (!e.target.files[0]) {
      console.log(`Что-то пошло не так! `);
      return;
    }
    let storageRef = storage.ref(newName);
    let task = storageRef.put(e.target.files[0]);

    task.on(
      "state_changed",
      (snap) => {
        setUploadPercentage(
          Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
        );
      },
      (err) => {
        console.log(err.code);
        if (err.code === "storage/unauthorized") {
          setModalUploadText("error");
          return;
        }
        return;
      },
      () => {
        storageRef
          .getDownloadURL()
          .then((url) => {
            // url = URL + storageRef + '?alt=media'
            // let time = new Date().getTime().toString()

            setStudent((prev) => ({
              ...prev,
              image: newName,
              imageThumb: "thumb_" + newName,
            }));
            db.ref(group + "/group/" + id + "/projects/" + project).update({
              image: newName,
              imageThumb: "thumb_" + newName,
            });
          })
          .then(() => {
            setTimeout(() => {
              setUploadPercentage(0);
              setModalUpload(false);
            }, 1500);
          });
      }
    );
  };

  const removeImage = () => {
    setStudent((prev) => ({ ...prev, image: DUMMY }));
    db.ref(
      currentGroup.dbName +
        "/group/" +
        student.id +
        "/projects/" +
        currentProject.name
    ).update({ image: "", imageThumb: "" });
  };

  return (
    <>
      {modalDelete && (
        <Modal
          removeImage={removeImage}
          setModalDelete={setModalDelete}
          student={student}
        />
      )}
      {modalUpload && (
        <ModalUpload
          text={modalUploadText}
          changeUpState={(modalUpload) => setModalUpload(modalUpload)}
        />
      )}
      {showStats && <Stats changeUpState={(close) => setShowStats(close)} />}
      {evaluatorMobile && (
        <div className="ev_container">
          {user ? <Evaluator user={user} /> : <Login />}
        </div>
      )}
      <div className="right">
        <div className="loader" style={{ width: `${uploadPercentage}%` }}></div>
        <div className="student-name-container">
          <div className="and_yet_again_container">
            <div
              className="small-btn-bars"
              onClick={() => setEvaluatorMobile((e) => !e)}
            >
              <FontAwesomeIcon icon={faBars} size="1x" />
            </div>
            <div className="select-part">
              <div className="select">
                <div className="group_name">{"Группа: "}</div>
                <select
                  onChange={(e) => {
                    setCurrentGroup(groups[e.currentTarget.value]);
                    setCurrent(0);
                  }}
                >
                  {groups.map((group, i) => (
                    <option key={i} value={i}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
              {student.id ? (
                <div className="select-student">
                  <div className="group_name">{`№${current + 1} / ${
                    student.id
                  } / `}</div>
                  <select
                    value={current}
                    onChange={(e) => {
                      setStudent(students[parseInt(e.currentTarget.value)]);
                      setCurrent(parseInt(e.currentTarget.value));
                    }}
                  >
                    {students.map((student, i) => (
                      <option key={i} value={i}>
                        {student.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
            <div className="select-part">
              <div className="select">
                <div className="group_name">{"Проект: "}</div>
                {currentGroup.projects && (
                  <select
                    value={currentGroup.projects.indexOf(currentProject)}
                    onChange={(e) =>
                      setCurrentProject(
                        currentGroup.projects[e.currentTarget.value]
                      )
                    }
                  >
                    {currentGroup.projects.map((project, i) => (
                      <option key={i} value={i}>
                        {project.name +
                          "  " +
                          (currentGroup.hours
                            ? currentGroup.hours[i]
                              ? "- " + currentGroup.hours[i]
                              : ""
                            : "")}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="student-score">
                {"Оценок:"}
                <div
                  className="score"
                  onMouseOver={() =>
                    setShowEvaluators(scores.size ? true : false)
                  }
                  onMouseOut={() => setShowEvaluators(false)}
                >
                  {scores.size ? scores.size : "0"}
                </div>
                {evaluators ? (
                  <div className="evaluators">
                    {Array.from(scores.keys()).map((evaluator) => (
                      <span>{evaluator}</span>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="top_btn_container">
            {!gallery ? (
              <div
                className="small-btn-open"
                title="полноэкранный просмотр"
                onMouseDown={() => setGallery(gallery ? false : true)}
              >
                <FontAwesomeIcon icon={faImage} size="1x" />
              </div>
            ) : (
              <div
                className="small-btn-open"
                title="просмотр в режиме галереи"
                onMouseDown={() => setGallery(gallery ? false : true)}
              >
                <FontAwesomeIcon icon={faThLarge} size="1x" />
              </div>
            )}

            {student.image && user ? (
              <div
                className="small-btn-delete"
                title="удалить проект"
                onMouseUp={() => setModalDelete(true)}
              >
                <FontAwesomeIcon icon={faTimesCircle} size="1x" />
              </div>
            ) : null}
          </div>
        </div>

        {gallery ? (
          <div className={modal ? "modal" : "imagecontainer"}>
            <div
              className={modal ? "hide" : "leftArrow"}
              onClick={() => {
                if (current === 0) setCurrent(students.length - 1);
                else setCurrent(current - 1);
              }}
            >
              {"<<"}
            </div>
            {loaded ? null : (
              <div className="loading-img">
                <div className="lds-dual-ring"></div>
              </div>
            )}
            <img
              alt=""
              className={
                modal
                  ? "image-out"
                  : student.image
                  ? "image-in"
                  : "image-in dum default"
              }
              onLoad={() => setLoaded(true)}
              onClick={() => {
                return student.image ? setModal(modal ? false : true) : null;
              }}
              src={student.image ? URL + student.image + "?alt=media" : DUMMY}
            />

            <div
              className={modal ? "hide" : "rightArrow"}
              onClick={() => {
                if (current === students.length - 1) setCurrent(0);
                else setCurrent(current + 1);
              }}
            >
              {">>"}
            </div>
          </div>
        ) : (
          <Gallery uploadPic={uploadPic} user={user} />
        )}

        {student.image || !gallery ? null : (
          <div className="bottom-button">
            <label className="btn-upload">
              {`  загрузить проект  `}
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={(e) => uploadPic(e)}
              />
            </label>
            <span className="info">{`формат *.jpg или *.png, максимум 10 Мб`}</span>
          </div>
        )}
      </div>
    </>
  );
};
