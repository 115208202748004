import React, { useState, useEffect } from "react";

let min = 2.5;
let max = 5.0;

const Slider = (props) => {
  const [score, setScore] = useState(props.initial);

  useEffect(() => {
    setScore(props.initial);
  }, [props]);

  return (
    <form className="form">
      <div className="slider-name">{props.id}</div>
      <div
        className="score-bulb"
        style={{ left: `${((score - 2.5) * 100) / 2.5 - 5}%` }}
      >
        {parseFloat(score).toFixed(2)}
      </div>
      <input
        type="range"
        id={props.id}
        name="score"
        min={min}
        max={max}
        value={score}
        step="0.05"
        onChange={(e) => setScore(e.target.value)}
        onMouseUp={() => props.changeUpState(score)}
        onTouchEnd={() => props.changeUpState(score)}
      />
      <div className="scale">
        <div>{min.toFixed(2)}</div>
        <div>{max.toFixed(2)}</div>
      </div>
    </form>
  );
};
export default Slider;
