import React, { useContext, useEffect } from 'react'
import { Context } from './context'
import { GalleryImage } from './galleryImage'
import { URL } from './right'

export const Gallery = props => {

    const { students } = useContext(Context)
    const { setCurrent } = useContext(Context)
    const { current } = useContext(Context)
    const { currentProject } = useContext(Context)
    const { gallery } = useContext(Context)
    const { setGallery } = useContext(Context)

    
    useEffect(() => {

        students.map(student => {
            let projects = student.projects ? new Map(Object.entries(student.projects)) : new Map()
            let projectscores =  projects.has(currentProject.name) ? Object.values(projects.get(currentProject.name))[2] : {}
            let scores = projectscores ? new Map(Object.entries(projectscores)) : new Map()
            let values = Array.from(scores.values())

            if(values.length) {
            // console.log('Student: ', student.name)
            let idea = 0
            let norms = 0
            let render = 0
            
                values.map(value => {
                    idea = idea + (+value.idea)
                    norms = norms + (+value.norms)
                    render = render + (+value.render)
                
                })
            

            student.idea = (idea / values.length).toFixed(2)
            student.norms = (norms / values.length).toFixed(2)
            student.render = (render / values.length).toFixed(2)
            
            // console.log('Scores: ', student.detailedScores)
            }

            if(props.user) {
                let thisScores = scores.has(props.user.displayName.split(' ')[0]) ? Object.values(scores.get(props.user.displayName.split(' ')[0])) : []
            
                // console.log(student.name.split(' ')[0],': ', thisScores)
         
                if(thisScores.includes(0) || !thisScores.length) {
                    student.unscored = true
                }
            }
            })
       
          
    }, [students, current, props])

   
   
    return (
        <div className='gallery'>
            {students.map((student, i) => (
                
                <div className={'gallery-cell ' + (i === current ? 'current-cell' : ((!student.unscored || !student.image) ? ' ' : 'cell-unscored'))} 
                    key={student.id}>
                    <GalleryImage image={student.imageThumb ? (URL + student.imageThumb + '?alt=media') : ''}/>
                    <div className='show-score'  onMouseUp={() => setCurrent(i)}>
                        <div style={{fontSize: '1.2rem', fontWeight: 'bold', width: '80%'}}>
                            {student.name.split(' ')[0]}
                        </div>
                        <div style={{fontSize: '1.2rem', fontWeight: 'bold', width: '80%'}}>
                            {student.name.split(' ')[1] + ' ' + (student.name.split(' ')[2] ? student.name.split(' ')[2] : '')}
                        </div>
                            {props.user ?
                            <div>
                            {' '}
                            </div>
                            :
                            <div>
                            {+student.score ? student.score : ''}
                            {(+student.score && currentProject.sliders) ?
                            <div>
                            <div className='scores-detailed' title='идея'>
                                <div>{'идея:'}</div>
                                <div>
                                    {student.idea}
                                </div>
                            </div>
                            <div className='scores-detailed' title='грамотность'>
                                <div>{'грамотность:'}</div>
                                <div  style={{paddingLeft: '1vw'}}>
                                    {student.norms}
                                </div>
                            </div>
                            <div className='scores-detailed' title='подача'>
                                <div>{'подача:'}</div>
                                <div>
                                    {student.render}
                                </div>
                            </div>
                            </div>  : null}
                           
                            </div>}
                        
                        
                        {!student.image && !props.user ? 
                        <div className='bottom-button gal'>
                            <label className='btn-upload' onMouseUp={() => setGallery(gallery ? false : true)}>
                                {`  к загрузке  `}
                                {/* <input  type='file'  accept='.png, .jpg, .jpeg' /> */}
                            </label>
                        {/* <span className='info'>{`формат *.jpg или *.png, максимум 20 Мб`}</span> */}
                        </div> : null}
                    </div> 
                </div>
            ))}
        </div>
    )
}
