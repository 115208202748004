import React, { useState, useContext } from "react";
import { Context } from "./context";
import "./modal.css";

export const ModalCreateProject = ({
  createProject,
  setModalCreateProject,
}) => {
  const { currentGroup } = useContext(Context);

  const [projectName, setProjectName] = useState("");
  const [sliders, setSliders] = useState(true);
  const [message, setMessage] = useState("");

  const validation = () => {
    let names = [];
    currentGroup.projects.forEach((project) => {
      names.push(project.name);
    });
    if (names.includes(projectName)) {
      setMessage("Проект с таким именем уже есть...");
      return;
    } else if (!projectName) {
      setMessage("Введите имя проекта...");
    } else {
      setModalCreateProject(false);
      createProject(projectName, sliders);
    }
  };

  return (
    <div className="back-drop">
      <div className="modal-container">
        <div className="modal-text">
          {"Создание нового проекта для группы " + currentGroup.name}
        </div>
        <div className="modal-col">
          <div>
            {"Имя проекта: "}
            <input
              type="text"
              className="prof-input"
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
              }}
            />
          </div>
          {message && (
            <div
              className="bold"
              onMouseUp={() => {
                setMessage(null);
                setProjectName("");
              }}
              title="закрыть"
              style={{ color: "red", cursor: "pointer" }}
            >
              {message}
            </div>
          )}
          <div
            style={{ cursor: "pointer" }}
            className="bigger bold"
            onClick={() => setSliders(sliders ? false : true)}
          >
            {"Количество категорий оценок -" + (sliders ? " три" : " одна")}
          </div>
        </div>
        <div className="modal-inner">
          <div
            className="modal-btn del"
            onClick={(e) => {
              e.preventDefault();
              validation();
            }}
          >
            {"Создать"}
          </div>
          <div
            className="modal-btn"
            onClick={(e) => {
              e.preventDefault();
              setModalCreateProject(false);
            }}
          >
            {"Отменить"}
          </div>
        </div>
      </div>
    </div>
  );
};
